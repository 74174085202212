import React from "react"
import { motion } from "framer-motion"

export interface BarsProps {}

export const Bars: React.FC<BarsProps> = ({ ...props }) => {
    return (
        <motion.g
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <motion.path
                initial={{ y: "300%" }}
                animate={{ y: 0 }}
                exit={{ y: "300%" }}
                d="M0 0H427V55.2861H0V0Z"
            />
            <motion.path
                initial={{ y: "100%" }}
                animate={{ y: 0 }}
                exit={{ y: "100%" }}
                d="M0 105.357H427V160.643H0V105.357Z"
            />
            <motion.path d="M0 211.04H427V266H0V211.04Z" />
        </motion.g>
    )
}
