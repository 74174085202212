import { motion } from "framer-motion"
import React from "react"

export interface DiamondsProps {}

const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
}

export const Diamonds: React.FC<DiamondsProps> = ({ ...props }) => {
    return (
        <motion.g
            variants={{
                hidden: { opacity: 1 },
                visible: { opacity: 1 },
            }}
            transition={{ staggerChildren: 0.01 }}
            initial="hidden"
            animate="visible"
            exit="hidden"
        >
            <motion.path
                variants={variants}
                d="M0 26.6L26.6875 0L53.375 26.6L26.6875 53.2L0 26.6Z"
            />
            <motion.path
                variants={variants}
                d="M53.375 26.6L80.0625 0L106.75 26.6L80.0625 53.2L53.375 26.6Z"
            />
            <motion.path
                variants={variants}
                d="M106.75 26.6L133.438 0L160.125 26.6L133.438 53.2L106.75 26.6Z"
            />
            <motion.path
                variants={variants}
                d="M160.125 26.6L186.812 0L213.5 26.6L186.812 53.2L160.125 26.6Z"
            />
            <motion.path
                variants={variants}
                d="M213.5 26.6L240.188 0L266.875 26.6L240.188 53.2L213.5 26.6Z"
            />
            <motion.path
                variants={variants}
                d="M266.875 26.6L293.562 0L320.25 26.6L293.562 53.2L266.875 26.6Z"
            />
            <motion.path
                variants={variants}
                d="M320.25 26.6L346.938 0L373.625 26.6L346.938 53.2L320.25 26.6Z"
            />
            <motion.path
                variants={variants}
                d="M373.625 26.6L400.312 0L427 26.6L400.312 53.2L373.625 26.6Z"
            />
            <motion.path
                variants={variants}
                d="M0 133L26.6875 106.4L53.375 133L26.6875 159.6L0 133Z"
            />
            <motion.path
                variants={variants}
                d="M53.375 133L80.0625 106.4L106.75 133L80.0625 159.6L53.375 133Z"
            />
            <motion.path
                variants={variants}
                d="M106.75 133L133.438 106.4L160.125 133L133.438 159.6L106.75 133Z"
            />
            <motion.path
                variants={variants}
                d="M160.125 133L186.812 106.4L213.5 133L186.812 159.6L160.125 133Z"
            />
            <motion.path
                variants={variants}
                d="M213.5 133L240.188 106.4L266.875 133L240.188 159.6L213.5 133Z"
            />
            <motion.path
                variants={variants}
                d="M266.875 133L293.562 106.4L320.25 133L293.562 159.6L266.875 133Z"
            />
            <motion.path
                variants={variants}
                d="M320.25 133L346.938 106.4L373.625 133L346.938 159.6L320.25 133Z"
            />
            <motion.path
                variants={variants}
                d="M373.625 133L400.312 106.4L427 133L400.312 159.6L373.625 133Z"
            />
            <motion.path
                variants={variants}
                d="M0 239.4L26.6875 212.8L53.375 239.4L26.6875 266L0 239.4Z"
            />
            <motion.path
                variants={variants}
                d="M53.375 239.4L80.0625 212.8L106.75 239.4L80.0625 266L53.375 239.4Z"
            />
            <motion.path
                variants={variants}
                d="M106.75 239.4L133.438 212.8L160.125 239.4L133.438 266L106.75 239.4Z"
            />
            <motion.path
                variants={variants}
                d="M160.125 239.4L186.812 212.8L213.5 239.4L186.812 266L160.125 239.4Z"
            />
            <motion.path
                variants={variants}
                d="M213.5 239.4L240.188 212.8L266.875 239.4L240.188 266L213.5 239.4Z"
            />
            <motion.path
                variants={variants}
                d="M266.875 239.4L293.562 212.8L320.25 239.4L293.562 266L266.875 239.4Z"
            />
            <motion.path
                variants={variants}
                d="M320.25 239.4L346.938 212.8L373.625 239.4L346.938 266L320.25 239.4Z"
            />
            <motion.path
                variants={variants}
                d="M373.625 239.4L400.312 212.8L427 239.4L400.312 266L373.625 239.4Z"
            />
        </motion.g>
    )
}
