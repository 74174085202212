import { Circles } from "./Circles"
import { Squares } from "./Squares"
import { Diamonds } from "./Diamonds"
import { Triangles } from "./Triangles"
import { Bars } from "./Bars"
import { FadingBars } from "./FadingBars"

export type ShapeKey =
    | "circles"
    | "squares"
    | "bars"
    | "fadingbars"
    | "triangles"
    | "diamonds"

export const shapes: Record<ShapeKey, React.FC> = {
    circles: Circles,
    squares: Squares,
    bars: Bars,
    fadingbars: FadingBars,
    triangles: Triangles,
    diamonds: Diamonds,
}
