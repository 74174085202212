import React from "react"
import { motion, Variants } from "framer-motion"

const variants: Variants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
    },
}

export interface SquaresProps {}

export const Squares: React.FC<SquaresProps> = ({ ...props }) => {
    return (
        <motion.g
            variants={{ hidden: { opacity: 1 }, visible: { opacity: 1 } }}
            transition={{ staggerChildren: 0.01, staggerDirection: -1 }}
            initial="hidden"
            animate="visible"
            exit="hidden"
        >
            <motion.rect variants={variants} x="2" width="55" height="55" />
            <motion.rect variants={variants} x="77" width="55" height="55" />
            <motion.rect variants={variants} x="152" width="55" height="55" />
            <motion.rect variants={variants} x="227" width="55" height="55" />
            <motion.rect variants={variants} x="302" width="55" height="55" />
            <motion.rect variants={variants} x="377" width="55" height="55" />
            <motion.rect variants={variants} y="105" width="55" height="55" />
            <motion.rect
                variants={variants}
                x="75"
                y="105"
                width="55"
                height="55"
            />
            <motion.rect
                variants={variants}
                x="150"
                y="105"
                width="55"
                height="55"
            />
            <motion.rect
                variants={variants}
                x="225"
                y="105"
                width="55"
                height="55"
            />
            <motion.rect
                variants={variants}
                x="300"
                y="105"
                width="55"
                height="55"
            />
            <motion.rect
                variants={variants}
                x="375"
                y="105"
                width="55"
                height="55"
            />
            <motion.rect variants={variants} y="211" width="55" height="55" />
            <motion.rect
                variants={variants}
                x="75"
                y="211"
                width="55"
                height="55"
            />
            <motion.rect
                variants={variants}
                x="150"
                y="211"
                width="55"
                height="55"
            />
            <motion.rect
                variants={variants}
                x="225"
                y="211"
                width="55"
                height="55"
            />
            <motion.rect
                variants={variants}
                x="300"
                y="211"
                width="55"
                height="55"
            />
            <motion.rect
                variants={variants}
                x="375"
                y="211"
                width="55"
                height="55"
            />
        </motion.g>
    )
}
