import React from "react"
import { motion, Variants } from "framer-motion"

export interface CirclesProps {}

const variants: Variants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
    },
}

export const Circles: React.FC<CirclesProps> = () => {
    return (
        <motion.g
            variants={{ hidden: { opacity: 0 }, visible: { opacity: 1 } }}
            transition={{
                staggerChildren: 0.01,
                staggerDirection: -1,
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
        >
            <motion.circle variants={variants} cx="302.5" cy="27.5" r="27.5" />
            <motion.circle variants={variants} cx="27.5" cy="27.5" r="27.5" />
            <motion.circle variants={variants} cx="357.5" cy="27.5" r="27.5" />
            <motion.circle variants={variants} cx="137.5" cy="27.5" r="27.5" />
            <motion.circle variants={variants} cx="192.5" cy="27.5" r="27.5" />
            <motion.circle variants={variants} cx="247.5" cy="27.5" r="27.5" />
            <motion.circle variants={variants} cx="82.5" cy="27.5" r="27.5" />
            <motion.circle variants={variants} cx="412.5" cy="28.5" r="27.5" />
            <motion.circle variants={variants} cx="302.5" cy="133.5" r="27.5" />
            <motion.circle variants={variants} cx="27.5" cy="133.5" r="27.5" />
            <motion.circle variants={variants} cx="357.5" cy="133.5" r="27.5" />
            <motion.circle variants={variants} cx="137.5" cy="133.5" r="27.5" />
            <motion.circle variants={variants} cx="192.5" cy="133.5" r="27.5" />
            <motion.circle variants={variants} cx="247.5" cy="133.5" r="27.5" />
            <motion.circle variants={variants} cx="82.5" cy="133.5" r="27.5" />
            <motion.circle variants={variants} cx="412.5" cy="134.5" r="27.5" />
            <motion.circle variants={variants} cx="302.5" cy="238.5" r="27.5" />
            <motion.circle variants={variants} cx="27.5" cy="238.5" r="27.5" />
            <motion.circle variants={variants} cx="357.5" cy="238.5" r="27.5" />
            <motion.circle variants={variants} cx="137.5" cy="238.5" r="27.5" />
            <motion.circle variants={variants} cx="192.5" cy="238.5" r="27.5" />
            <motion.circle variants={variants} cx="247.5" cy="238.5" r="27.5" />
            <motion.circle variants={variants} cx="82.5" cy="238.5" r="27.5" />
            <motion.circle variants={variants} cx="412.5" cy="239.5" r="27.5" />
        </motion.g>
    )
}
