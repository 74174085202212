import React from "react"
import { motion } from "framer-motion"

export interface TrianglesProps {}

export const Triangles: React.FC<TrianglesProps> = () => {
    return (
        <motion.g
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0, opacity: 0 }}
        >
            <path d="M28.3033 2.44788L56.6067 53.8528H0L28.3033 2.44788Z" />
            <path d="M81.2169 51.4049L109.52 0H52.9135L81.2169 51.4049Z" />
            <path d="M134.13 2.44788L162.434 53.8528H105.827L134.13 2.44788Z" />
            <path d="M187.044 51.4049L215.347 0H158.74L187.044 51.4049Z" />
            <path d="M239.957 2.44788L268.261 53.8528H211.654L239.957 2.44788Z" />
            <path d="M292.871 51.4049L321.174 0H264.567L292.871 51.4049Z" />
            <path d="M345.784 2.44788L374.087 53.8528H317.481L345.784 2.44788Z" />
            <path d="M398.698 51.4049L427 0H370.395L398.698 51.4049Z" />
            <path d="M28.3033 155.847L56.6067 104.442H0L28.3033 155.847Z" />
            <path d="M81.2169 106.89L109.52 158.294H52.9135L81.2169 106.89Z" />
            <path d="M134.13 155.847L162.434 104.442H105.827L134.13 155.847Z" />
            <path d="M187.044 106.89L215.347 158.294H158.74L187.044 106.89Z" />
            <path d="M239.957 155.847L268.261 104.442H211.654L239.957 155.847Z" />
            <path d="M292.871 106.89L321.174 158.294H264.567L292.871 106.89Z" />
            <path d="M345.784 155.847L374.087 104.442H317.481L345.784 155.847Z" />
            <path d="M398.698 106.89L427 158.294H370.395L398.698 106.89Z" />
            <path d="M28.3033 214.595L56.6067 266H0L28.3033 214.595Z" />
            <path d="M81.2169 263.552L109.52 212.147H52.9135L81.2169 263.552Z" />
            <path d="M134.13 214.595L162.434 266H105.827L134.13 214.595Z" />
            <path d="M187.044 263.552L215.347 212.147H158.74L187.044 263.552Z" />
            <path d="M239.957 214.595L268.261 266H211.654L239.957 214.595Z" />
            <path d="M292.871 263.552L321.174 212.147H264.567L292.871 263.552Z" />
            <path d="M345.784 214.595L374.087 266H317.481L345.784 214.595Z" />
            <path d="M398.698 263.552L427 212.147H370.395L398.698 263.552Z" />
        </motion.g>
    )
}
