import React from "react"
import { motion } from "framer-motion"

export const FadingBars: React.FC = () => {
    return (
        <g>
            <motion.path
                animate={{ opacity: [1, 0] }}
                transition={{
                    duration: 1,
                    yoyo: Infinity,
                    repeatDelay: 1,
                }}
                d="M0 0H427V55.2861H0V0Z"
            />
            <motion.path
                animate={{ opacity: [1, 0] }}
                transition={{
                    delay: 0.3,
                    duration: 1,
                    yoyo: Infinity,
                    repeatDelay: 1,
                }}
                d="M0 105.357H427V160.643H0V105.357Z"
            />
            <motion.path
                animate={{ opacity: [1, 0] }}
                transition={{
                    delay: 0.6,
                    duration: 1,
                    yoyo: Infinity,
                    repeatDelay: 1,
                }}
                d="M0 211.04H427V266H0V211.04Z"
            />
        </g>
    )
}
