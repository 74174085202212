import { AnimatePresence } from "framer-motion"
import React from "react"
import { ShapeKey, shapes } from "./shapes"

export interface StatefulShapesProps {
    shape: ShapeKey
}

export const StatefulShapes: React.FC<StatefulShapesProps> = ({
    shape,
    ...props
}) => {
    const ShapeComponent = shapes[shape]

    return (
        <AnimatePresence exitBeforeEnter>
            <ShapeComponent />
        </AnimatePresence>
    )
}
