import { AnimatePresence, useCycle } from "framer-motion"
import React, { useEffect } from "react"
import { ShapeKey, shapes as shapeComponents } from "./shapes"

export interface CyclingShapesProps {
    shapes: ShapeKey[]
    interval?: number
}

export const CyclingShapes: React.FC<CyclingShapesProps> = ({
    shapes,
    interval = 4000,
    ...props
}) => {
    const [sk, cycleSk] = useCycle(...shapes)
    const ShapeComponent = shapeComponents[sk]

    useEffect(() => {
        const t = setInterval(() => cycleSk(), interval)
        return () => clearInterval(t)
    }, [cycleSk])

    return (
        <AnimatePresence exitBeforeEnter>
            <ShapeComponent key={sk} />
        </AnimatePresence>
    )
}
