import React, { PropsWithoutRef } from 'react'
import { CyclingShapes } from './CyclingShapes'
import { ShapeKey } from './shapes'
import { StatefulShapes } from './StatefulShapes'

export interface LogoProps {
    /** Default stateful with fading bars */
    variant?: 'cycle' | 'stateful'
    /** Used when variant is set to stateful */
    shape?: ShapeKey
    /** Used when variant is set to cycle */
    shapes?: ShapeKey[]
}

export const Logo: React.FC<
    LogoProps & PropsWithoutRef<JSX.IntrinsicElements['svg']>
> = ({
    variant = 'stateful',
    shape = 'fadingbars',
    shapes = ['bars', 'circles', 'triangles', 'squares', 'diamonds'],
    ...props
}) => {
    return (
        <svg viewBox="0 0 1280 590" fill="currentColor" {...props}>
            <g id="Smash Delta Logo">
                <path
                    id="S"
                    d="M51.3108 140.056C39.0938 133.53 28.4285 124.444 20.0431 113.42C11.7515 102.132 7.45735 88.4036 7.839 74.4028C7.55696 60.2735 12.185 46.4838 20.934 35.3854C30.4808 23.7573 43.0149 14.9496 57.1901 9.90807C73.8933 3.74832 91.5909 0.728244 109.391 0.999953C126.682 0.689395 143.85 3.96518 159.811 10.6207C173.783 16.4239 185.805 26.0976 194.464 38.5031C203.046 51.3794 207.433 66.5954 207.024 82.0639H140.748C140.932 78.1705 140.271 74.2834 138.81 70.6697C137.35 67.056 135.124 63.8014 132.286 61.1296C126.644 55.9629 118.478 53.3795 107.788 53.3795C101.536 53.23 95.3106 54.2574 89.4377 56.4083C84.9515 57.9952 80.959 60.7289 77.8573 64.3376C75.3319 67.3625 73.9452 71.176 73.9376 75.1165C73.9376 81.6491 77.7087 87.2315 85.2508 91.8636C96.2345 97.9823 107.854 102.881 119.903 106.473C148.526 115.737 170.172 127.258 184.844 141.036C192.056 147.752 197.75 155.931 201.544 165.026C205.339 174.121 207.147 183.921 206.846 193.772C207.264 208.067 203.079 222.119 194.91 233.858C186.378 245.338 174.672 254.071 161.237 258.978C144.878 265.003 127.533 267.904 110.104 267.531C91.7661 267.67 73.5586 264.439 56.3882 257.999C40.2835 252.075 26.1742 241.732 15.6783 228.157C5.22611 214.616 0 197.631 0 177.202H66.8109C66.4275 185.081 68.0521 192.928 71.5322 200.007C74.6401 205.56 79.6811 209.777 85.6959 211.855C93.5711 214.411 101.829 215.586 110.104 215.329C120.438 215.329 128.069 213.28 132.998 209.182C135.307 207.412 137.175 205.132 138.458 202.521C139.74 199.909 140.402 197.037 140.392 194.128C140.542 189.608 139.431 185.136 137.185 181.211C133.93 176.747 129.613 173.165 124.625 170.789C115.667 166.212 106.34 162.398 96.7423 159.386C81.0695 154.264 65.8691 147.797 51.3108 140.056V140.056Z"
                />
                <path
                    id="M"
                    d="M520.59 263.967H453.957V214.973L460.371 83.1328L400.687 263.967H357.215L297.531 83.1328L304.123 214.973V263.967H237.49V4.56488H324.967L378.951 176.67L432.934 4.56488H520.589L520.59 263.967Z"
                />
                <path
                    id="A"
                    d="M705.876 221.03H622.496L609.312 263.967H537.872L632.473 4.56488H695.541L790.682 263.969H719.06L705.876 221.03ZM689.307 167.225L664.008 85.27L638.888 167.225H689.307Z"
                />
                <path
                    id="S_2"
                    d="M851.437 140.056C839.221 133.528 828.556 124.443 820.17 113.42C811.878 102.133 807.584 88.4037 807.966 74.4029C807.683 60.2735 812.311 46.4838 821.06 35.3855C830.607 23.7574 843.141 14.9497 857.316 9.90816C874.02 3.74861 891.717 0.728541 909.518 1.00004C926.809 0.689527 943.976 3.96531 959.938 10.6208C973.909 16.4232 985.932 26.0971 994.59 38.5032C1003.17 51.3795 1007.56 66.5955 1007.15 82.064H940.874C941.059 78.1705 940.398 74.2832 938.938 70.6694C937.477 67.0556 935.25 63.8011 932.412 61.1297C926.77 55.963 918.605 53.3796 907.915 53.3796C901.662 53.2302 895.437 54.2577 889.564 56.4084C885.078 57.996 881.086 60.7296 877.984 64.3377C875.458 67.3626 874.071 71.1761 874.064 75.1165C874.064 81.6492 877.835 87.2316 885.377 91.8637C896.361 97.982 907.981 102.881 920.03 106.473C948.654 115.737 970.301 127.258 984.97 141.037C992.182 147.752 997.876 155.931 1001.67 165.026C1005.47 174.121 1007.27 183.922 1006.97 193.772C1007.39 208.068 1003.21 222.12 995.036 233.858C986.504 245.339 974.798 254.071 961.363 258.978C945.004 265.004 927.66 267.905 910.231 267.531C891.893 267.67 873.685 264.439 856.515 257.999C840.41 252.074 826.301 241.732 815.805 228.157C805.351 214.616 800.125 197.632 800.125 177.202H866.936C866.553 185.081 868.177 192.928 871.657 200.007C874.766 205.559 879.807 209.776 885.821 211.855C893.696 214.411 901.954 215.586 910.229 215.329C920.563 215.329 928.194 213.28 933.123 209.182C935.433 207.413 937.302 205.133 938.585 202.522C939.868 199.91 940.53 197.038 940.52 194.128C940.67 189.608 939.559 185.136 937.313 181.211C934.058 176.747 929.741 173.165 924.753 170.789C915.795 166.212 906.468 162.398 896.87 159.386C881.197 154.265 865.996 147.797 851.437 140.056Z"
                />
                <path
                    id="H"
                    d="M1256.93 4.56488V263.969H1190.66V158.853H1104.07V263.967H1037.62V4.56488H1104.07V105.225H1190.66V4.56488H1256.93Z"
                />
                <path
                    id="D"
                    d="M151.48 339.572C170.206 350.422 185.544 366.266 195.78 385.334C206.651 405.642 212.163 428.387 211.797 451.419V461.485C212.172 484.478 206.692 507.189 195.871 527.481C185.718 546.463 170.513 562.268 151.939 573.149C132.831 584.251 111.063 589.946 88.9663 589.626H0V323.095H87.3188C109.798 322.721 131.963 328.413 151.48 339.572V339.572ZM141.871 451.055C141.871 427.133 137.233 409.041 127.957 396.778C118.681 384.515 105.135 378.383 87.3188 378.381H68.2811V534.528H88.9659C105.929 534.528 118.987 528.425 128.142 516.219C137.292 504.018 141.869 485.773 141.871 461.485V451.055Z"
                />
                <path
                    id="L"
                    d="M874.08 534.621V589.719H695.971V323.189H764.252V534.621H874.08Z"
                />
                <path
                    id="T"
                    d="M1055.87 378.474H976.423V589.719H907.96V378.474H830.528V323.189H1055.87V378.474Z"
                />
                <path
                    id="A_2"
                    d="M1192.86 545.603H1107.19L1093.65 589.719H1020.24L1117.45 323.189H1182.25L1280 589.719H1206.41L1192.86 545.603ZM1182.5 490.321L1156.51 406.116L1130.7 490.321H1182.5Z"
                />
            </g>

            <svg
                x="237"
                y="323"
                width="427"
                height="276"
                fill="currentColor"
                style={{ overflow: 'visible' }}
            >
                {variant === 'cycle' && <CyclingShapes shapes={shapes} />}
                {variant === 'stateful' && <StatefulShapes shape={shape} />}
            </svg>
        </svg>
    )
}
